import { Typography } from '@mui/material';
import styled from '@emotion/styled';
const Title = styled(Typography)(() => ({
    fontSize: 32,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '40px',
    letterSpacing: '-0.704px',
}));
const TitleSecondary = styled(Typography)(() => ({
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '19px',
}));
const Subtitle = styled(Typography)(() => ({
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '-0.176px',
}));
const Body = styled(Typography)(() => ({
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 400,
}));
const BodySemiBold = styled(Body)(() => ({
    fontWeight: 500,
}));
const BodyBold = styled(Body)(() => ({
    fontWeight: 600,
}));
const BodySmall = styled(Body)(() => ({
    fontSize: 12,
}));
const BodyExtraSmall = styled(Body)(() => ({
    fontSize: 10,
    lineHeight: '12px',
}));
const BodySmallSemiBold = styled(BodySmall)(() => ({
    fontWeight: 500,
}));
const BodySmallBold = styled(BodySmall)(() => ({
    fontWeight: 600,
}));
const BodyMedium = styled(Body)(() => ({
    fontSize: 16,
}));
const BodyMediumSemiBold = styled(BodyMedium)(() => ({
    fontWeight: 500,
}));
const BodyMediumBold = styled(BodyMedium)(() => ({
    fontWeight: 600,
}));
const BodyLarge = styled(Body)(() => ({
    fontSize: 18,
}));
const BodyLargeSemiBold = styled(BodyLarge)(() => ({
    fontWeight: 500,
}));
const BodyLargeBold = styled(BodyLarge)(() => ({
    fontWeight: 600,
}));
const TextEmphasized = styled(Typography)(() => ({
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
}));
const TextEmphasizedSemiBold = styled(TextEmphasized)(() => ({
    fontWeight: 500,
}));
const TextEmphasizedMedium = styled(TextEmphasized)(() => ({
    fontSize: 24,
}));
const TextEmphasizedMediumSemiBold = styled(TextEmphasizedMedium)(() => ({
    fontWeight: 500,
}));
const TextEmphasizedMediumBold = styled(TextEmphasizedMedium)(() => ({
    fontWeight: 600,
}));
const ActiveText = styled(Typography)(() => ({
    fontSize: 12,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
}));
const ActiveTextBold = styled(ActiveText)(() => ({
    fontWeight: 600,
}));
export { Title, TitleSecondary, Subtitle, Body, BodySemiBold, BodyBold, BodySmall, BodySmallSemiBold, BodySmallBold, BodyExtraSmall, BodyMedium, BodyMediumSemiBold, BodyMediumBold, BodyLarge, BodyLargeSemiBold, BodyLargeBold, TextEmphasized, TextEmphasizedSemiBold, TextEmphasizedMedium, TextEmphasizedMediumSemiBold, TextEmphasizedMediumBold, ActiveText, ActiveTextBold, };
